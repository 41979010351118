<template>
  <div>
    <!-- 发票详情部分 -->
    <div class="invoice-info">
      <div class="person-header">
        <p>
          <strong>发票详情</strong>
          <b class="f-14 b" @click="$router.push( '/home/hirer/account/invoice')">返回</b>
        </p>
      </div>

      <InvoiceInfo :invoiceData="infoData" />

      <!-- 纸质发票的收货地址 -->
      <div class="invoice-info-paper" v-if="infoData.style==2">
        <el-descriptions :label-style="{width:'100px'}" class="margin-top" title="收货地址" size="small" :column="3" border>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              收件人
            </template>
            {{ infoData.address.fullname }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-mobile-phone"></i>
              手机号
            </template>
            {{ infoData.address.mobile_format }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              居住地
            </template>
            {{ checkedAddressName( 0, 0, infoData.address.region_id )}}
          </el-descriptions-item>
<!--          <el-descriptions-item>-->
<!--            <template slot="label">-->
<!--              <i class="el-icon-tickets"></i>-->
<!--              备注-->
<!--            </template>-->
<!--            <el-tag size="small">家</el-tag>-->
<!--          </el-descriptions-item>-->
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-office-building"></i>
              详细地址
            </template>
            {{ infoData.address.address }}
          </el-descriptions-item>
        </el-descriptions>

        <el-descriptions style="margin-top: 40px" :label-style="{width:'100px'}" title="物流信息" size="small" :column="2" border>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-office-building"></i>
              快递公司
            </template>
            {{ infoData.logistics_company }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-document"></i>
              快递单号
            </template>
            <el-tag v-if="infoData.logistics_number" type="info" size="mini"> {{ infoData.logistics_number }} </el-tag>
            <b v-if="infoData.logistics_number" class="b f-12 ml-20 " style="cursor: grab; float: right" @click="copyUrl(infoData.logistics_number)">复制单号</b>
          </el-descriptions-item>
        </el-descriptions>

      </div>

      <!-- 电子发票接收信息 -->
      <div class="invoice-info-email" v-if="infoData.style==1">
        <el-descriptions title="电子发票信息" size="small" direction="vertical" :column="1" border>
          <el-descriptions-item label="邮箱地址">{{infoData.email}}</el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              电子发票
            </template>
            <el-link v-if="infoData.status>3" type="primary" @click="downloadAttr(infoData.file)" >点击下载</el-link>
            <el-link v-else type="info" style="font-size: 12px" > 开具后可查看下载 </el-link>
          </el-descriptions-item>
        </el-descriptions>
      </div>

    </div>
    <div class="mb-20"></div>
    <InvoiceInTabel :invoiceId="invoiceId"></InvoiceInTabel>
  </div>
</template>
<script>
import InvoiceInfo from "@/views/layout/modules/hirer/invoiceInfo"
import InvoiceInTabel from "@/views/layout/modules/hirer/InvoiceInfoTabel"
import {getInfo} from '@/api/invoice'

export default {
  components: {
    InvoiceInfo,
    InvoiceInTabel
  },
  data() {
    return {
      infoData: {
        address: {}
      },
      invoiceId: 0,
    }
  },
  mounted() {
    if (this.$route.query.id != '') {
      this.invoiceId = this.$route.query.id
      this.loadInfo({id: this.$route.query.id})
    } else {

    }
  },
  methods: {
    async loadInfo(data) {
      const res = await getInfo(data)
      if (res.statusCode == 200) {
        this.infoData = res.data
      }
    },
    copyUrl(val) {
      // 模拟 输入框
      var cInput = document.createElement("input");
      cInput.value = val;
      document.body.appendChild(cInput);
      cInput.select(); // 选取文本框内容

      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
      // Input要在正常的编辑状态下原生复制方法才会生效

      document.execCommand("copy");

      this.$message({
        type: "success",
        message: "复制成功"
      });
      // 复制成功后再将构造的标签 移除
      document.body.removeChild(cInput);
    },
    checkedAddressName(province_id, city_id, county_id) {
      var region = this.$regionJson.data;
      var address = '';
      region.forEach(province => {
        if (province.c == province_id) {
          address = province.n;
        }
        province.s.forEach(city => {
          if (city.c == city_id) {
            address += '-' + city.n;
          }
          city.s.forEach(country => {
            if (country.c == county_id) {
              if( address=='' ){
                address = province.n + '-' + city.n + '-' + country.n;
              }else {
                address += '-' + country.n;
              }
            }
          });
        });
      });

      return address;
    },
    downloadAttr(url) {
      console.log(url);
      window.open(url, '_blank')
    },
  }
}
</script>

<style lang="less" scoped>
.invoice-info {
  background-color: #fff;

  .person-header {
    p {
      width: 860px;
      height: 18px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
    }
  }
}

.invoice-info-email {
  width: 860px;
  margin: 0 auto;
  padding-bottom: 40px;

  b {
    cursor: pointer;
  }
}

.invoice-info-paper {
  box-sizing: border-box;
  width: 860px;
  margin: 0 auto;
  padding-bottom: 40px;
}

.invoice-address {
  font-size: 14px;
  line-height: 24px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.invoice-shopping {
  font-size: 14px;
  line-height: 24px;
  padding-top: 20px;
  padding-bottom: 20px;
}
</style>
