<template>
  <div class="invoice">
    <!-- 此页面是发票详情的共有的部分 -->
    <div class="invoice-info">
      <div class="mb-40"></div>
      <el-descriptions title="发票详情" :label-style="{width:'100px'}" :column="1" border>
        <template slot="extra">
          发票金额：<span class="f-14 r">¥ {{ invoiceInfoData.amount|moneyFormat }}</span>
        </template>
        <el-descriptions-item label="发票介质">{{ invoiceInfoData.style_name }}</el-descriptions-item>
        <el-descriptions-item label="发票类型">{{ invoiceInfoData.type_name }}</el-descriptions-item>
        <el-descriptions-item label="发票状态">
          <el-tag size="mini" type="primary" v-if="invoiceInfoData.status==1">{{invoiceInfoData.status_name}}</el-tag>
          <el-tag size="mini" type="danger" v-if="invoiceInfoData.status==2">{{invoiceInfoData.status_name}}</el-tag>
          <el-tag size="mini" type="success" v-if="invoiceInfoData.status==3">{{invoiceInfoData.status_name}}</el-tag>
          <el-tag size="mini" type="warning" v-if="invoiceInfoData.status==4">{{invoiceInfoData.status_name}}</el-tag>
          <el-tag size="mini" type="info" v-if="invoiceInfoData.status==5">{{invoiceInfoData.status_name}}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="发票抬头">{{ invoiceInfoData.certification.company_name }}</el-descriptions-item>
        <el-descriptions-item label="发票税号">{{ invoiceInfoData.tax_id_num }}</el-descriptions-item>
        <el-descriptions-item label="发票内容">{{ invoiceInfoData.service_name }}</el-descriptions-item>
        <el-descriptions-item label="申请时间">{{ invoiceInfoData.apply_at }}</el-descriptions-item>
        <el-descriptions-item label="开票时间">{{ invoiceInfoData.open_at }}</el-descriptions-item>
      </el-descriptions>
      <div class="mb-20"></div>
      <el-descriptions title="企业详情" v-if="invoiceInfoData.type==2" :label-style="{width:'100px'}" :column="1" border>
        <el-descriptions-item label="企业地址">{{ invoiceInfoData.company_address }}</el-descriptions-item>
        <el-descriptions-item label="企业电话">{{ invoiceInfoData.company_phone }}</el-descriptions-item>
        <el-descriptions-item label="开户银行">{{ invoiceInfoData.company_bank_name }}</el-descriptions-item>
        <el-descriptions-item label="银行卡号">{{ invoiceInfoData.company_bank_number }}</el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    invoiceData: {
      default: {}
    }
  },
  data() {
    return {
      invoiceInfoData: {
        certification: {
          company_name: ''
        }
      }
    }
  },
  watch: {
    invoiceData( newval ) {
      if( newval ) {
        this.invoiceInfoData = this.invoiceData
      }
    }
  }
}
</script>

<style lang="less" scoped>
.invoice {
  background-color: #fff;
}

.invoice-info {

  width: 860px;
  margin: 0 auto;
  padding-bottom: 40px;
}

.invoce-info-center {
  display: flex;

  .invoice-info-center-left {
    width: 450px;

    ul {
      li {
        margin-bottom: 20px;

        span {
          display: inline-block;
          width: 65px;
          text-align: right;
        }

        strong {
          display: inline-block;
          margin-left: 20px;
          font-size: 14px;
        }
      }
    }
  }

  .invoice-info-center-right {
    ul {
      li {
        margin-bottom: 20px;

        span {
          display: inline-block;
          width: 80px;
          text-align: right;
          margin-right: 20px;
        }

        b {
          margin-left: 20px;
        }

        strong {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
