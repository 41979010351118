var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invoiceinfo-tabel"},[_vm._m(0),_c('div',{staticClass:"mb-20"}),_c('el-table',{key:"two",staticClass:"accountTabel",staticStyle:{"width":"860px"},attrs:{"data":_vm.invoiceData,"center":"","border":"","header-cell-style":{
      'text-align': 'center',
      height: '36px',
      color: '#000',
      'font-size': '14px',
      background: '#f4f4f4',
    },"cell-style":{
      'text-align': 'center',
      height: '50px',
      color: '#575757',
      'font-size': '12px',
    }}},[_vm._v(" > "),_c('el-table-column',{attrs:{"label":"序号","prop":"id","width":"50px"}}),_c('el-table-column',{attrs:{"label":"项目名称","prop":"task_name","width":"319px"}}),_c('el-table-column',{attrs:{"label":"项目编号","prop":"sn","width":"120px"}}),_c('el-table-column',{attrs:{"label":"周期编号","prop":"number","width":"79px"}}),_c('el-table-column',{attrs:{"label":"放款时间","prop":"real_grant_time","width":"159px"}}),_c('el-table-column',{attrs:{"label":"放款金额","prop":"grant_success_amount","width":"132px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" ￥"+_vm._s(_vm._f("moneyFormat")(scope.row.grant_success_amount))+" ")]}}])})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"person-header"},[_c('p',[_c('strong',[_vm._v("发票开具项目清单")])])])}]

export { render, staticRenderFns }