<template>
  <div class="invoiceinfo-tabel">
    <div class="person-header">
      <p>
        <strong>发票开具项目清单</strong>
      </p>
    </div>
    <div class="mb-20"></div>
    <el-table
      :data="invoiceData"
      style="width: 860px"
      center
      class="accountTabel"
      border
      key="two"
      :header-cell-style="{
        'text-align': 'center',
        height: '36px',
        color: '#000',
        'font-size': '14px',
        background: '#f4f4f4',
      }"
      :cell-style="{
        'text-align': 'center',
        height: '50px',
        color: '#575757',
        'font-size': '12px',
      }"
    >
      >
      <el-table-column label="序号"  prop="id" width="50px"></el-table-column>
      <el-table-column label="项目名称" prop="task_name" width="319px"></el-table-column>
      <el-table-column label="项目编号" prop="sn" width="120px"></el-table-column>
      <el-table-column label="周期编号" prop="number" width="79px"></el-table-column>
      <el-table-column label="放款时间"  prop="real_grant_time" width="159px"></el-table-column>
      <el-table-column label="放款金额"  prop="grant_success_amount" width="132px">
        <template slot-scope="scope" >
          ￥{{scope.row.grant_success_amount|moneyFormat}}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getPeriodsForInvoiceId } from '@/api/invoice'
export default {
  props : {
    invoiceId: {
      default: 0
    }
  },
  data(){
    return{
      invoiceData:[]
    }
  },
  watch: {
    invoiceId( newval, oldval ){
      if( newval > 0 ){
        this.loadPeriods( {id: this.invoiceId} );
      }
    }
  },
  methods: {
    async loadPeriods( data ){
      const res = await getPeriodsForInvoiceId( data )
      if( res.statusCode == 200 ){
        this.invoiceData = res.data
      }
    }
  }
};
</script>

<style lang="less" scoped>
.invoiceinfo-tabel {
  width: 940px;
  background-color: #fff;
  padding-bottom: 40px;
  .accountTabel{
    margin: 0 auto;
  }
}
</style>
